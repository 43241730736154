import React, { useState, useEffect, useRef} from 'react';
import './navbar.css'
import { Link, NavLink } from 'react-router-dom';

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false);

  const rectangleRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (rectangleRef.current && !rectangleRef.current.contains(event.target)) {
        setToggleMenu(false);
      }
    };

    if (toggleMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleMenu]);



    // Effect to control body overflow when toggleMenu changes
    useEffect(() => {
      if (toggleMenu) {
        // Prevent scrolling
        document.body.style.overflow = 'hidden';
      } else {
        // Allow scrolling
        document.body.style.overflow = 'auto';
      }
  
      // Cleanup effect when component unmounts
      return () => {
        document.body.style.overflow = 'auto';
      };
    }, [toggleMenu]);

  return (
    <div className='navbar' >
      <div className="wise__navbar">
        <div className="links_logo">
          <a href="https://wisevisiontechnologies.com" target="_blank" rel="noopener noreferrer">
            <svg
              className="logo-f-1"
              width="38"
              height="32"
              viewBox="0 0 38 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >

              <g clip-path="url(#clip0_435_88)">
                <path
                  d="M19.9969 6.43809L14.3281 25.7764C14.3007 25.8747 14.2556 25.9667 14.1954 26.0476L13.7295 27.9676C13.1387 30.6008 11.9076 31.9161 10.0258 31.9161C8.14403 31.9161 6.86868 30.6469 6.15293 28.1438L0.426905 7.96758C0.301974 7.61775 0.301974 7.26792 0.166631 6.96147C0.0540207 6.4108 0.00249566 5.84847 0.0130698 5.28554C0.0130698 3.38724 0.684576 1.86588 2.54033 1.33978C2.88975 1.20531 3.26132 1.14447 3.63348 1.1608C5.3617 1.1608 6.53814 2.51673 7.20964 5.24487L9.95553 16.4964L13.6931 3.78046L13.7607 3.81843C13.9333 3.90481 14.1212 3.95284 14.3125 3.95944H14.375L12.1757 11.455L12.6494 13.0496L13.9117 8.75131C14.0122 8.80016 14.121 8.82781 14.2318 8.83266H14.2657L12.8446 13.6896L13.3833 15.493L15.7258 7.46317C15.8989 7.55366 16.0892 7.60277 16.2828 7.6069H16.3453L13.7009 16.6022L14.3412 18.7527L16.6081 11.032C16.6887 11.0754 16.7778 11.0986 16.8684 11.0998H16.8944C16.8957 11.1124 16.8957 11.1251 16.8944 11.1377L14.4921 19.3005L15.0257 21.0822L19.3644 6.31334C19.5369 6.4025 19.7263 6.45067 19.9188 6.45436L19.9969 6.43809Z"
                  fill="white"
                />
                <path
                  d="M31.0585 2.85573H31.1183C31.4581 2.85497 31.7841 2.71579 32.0267 2.46793C32.2932 2.20961 32.4505 1.85167 32.464 1.47268C32.4715 1.28621 32.4435 1.10004 32.3814 0.924984C32.3193 0.749931 32.2243 0.589495 32.1022 0.453015C31.9839 0.314893 31.8398 0.203457 31.6787 0.12557C31.5175 0.0476834 31.3428 0.00499413 31.1652 0.000133868C30.809 -0.00493412 30.4647 0.134068 30.2048 0.387931C30.0308 0.560201 29.9018 0.77579 29.83 1.01437C29.7964 1.13458 29.7754 1.25825 29.7675 1.38318C29.7593 1.57095 29.7871 1.75853 29.8492 1.935C29.9113 2.11146 30.0065 2.27327 30.1293 2.41098C30.2371 2.53674 30.3669 2.63984 30.5119 2.71471C30.6824 2.80159 30.8687 2.84966 31.0585 2.85573ZM30.5822 1.95268C30.4502 1.80278 30.3806 1.60458 30.3884 1.40136C30.3962 1.19813 30.4808 1.00637 30.6238 0.867932C30.6933 0.799581 30.7751 0.74619 30.8644 0.710823C30.9537 0.675456 31.0489 0.658807 31.1444 0.661829C31.2389 0.664774 31.3319 0.68713 31.4181 0.727617C31.5043 0.768104 31.582 0.825926 31.6467 0.897762C31.7754 1.04607 31.8443 1.24028 31.8393 1.44014C31.838 1.50734 31.8275 1.57401 31.8081 1.6381C31.7702 1.7649 31.7022 1.87966 31.6103 1.97166C31.4699 2.10993 31.2828 2.18496 31.0897 2.18047C30.9945 2.18185 30.9001 2.16227 30.8126 2.12302C30.7251 2.08376 30.6466 2.02572 30.5822 1.95268Z"
                  fill="white"
                />
                <path
                  d="M26.3006 16.7675L30.504 2.45424C30.5139 2.40391 30.5337 2.35627 30.5623 2.31439C30.5909 2.27251 30.6275 2.23731 30.6699 2.21104C30.7122 2.18477 30.7594 2.16801 30.8084 2.16185C30.8573 2.15568 30.907 2.16024 30.9541 2.17522C31.0013 2.19021 31.0449 2.2153 31.0822 2.24889C31.1196 2.28247 31.1497 2.3238 31.1708 2.37024C31.1919 2.41668 31.2035 2.4672 31.2047 2.51855C31.206 2.56991 31.1969 2.62098 31.1781 2.66848L26.9747 16.9817C26.9649 17.032 26.945 17.0797 26.9165 17.1216C26.8879 17.1634 26.8513 17.1986 26.8089 17.2249C26.7665 17.2512 26.7193 17.2679 26.6704 17.2741C26.6214 17.2803 26.5718 17.2757 26.5246 17.2607C26.4775 17.2457 26.4338 17.2206 26.3965 17.1871C26.3592 17.1535 26.329 17.1121 26.3079 17.0657C26.2868 17.0193 26.2753 16.9688 26.274 16.9174C26.2728 16.866 26.2818 16.815 26.3006 16.7675Z"
                  fill="white"
                />
                <path
                  d="M33.0394 6.5004H33.0993C33.4378 6.49966 33.7627 6.36153 34.005 6.11532C34.271 5.8581 34.4281 5.50139 34.4417 5.12358C34.4554 4.74576 34.3245 4.37775 34.0779 4.10041C33.96 3.96261 33.8162 3.85137 33.6556 3.77349C33.4949 3.69562 33.3207 3.65275 33.1435 3.64752C32.7865 3.63723 32.4395 3.77135 32.1753 4.02176C32.0021 4.19419 31.874 4.4098 31.8031 4.6482C31.7685 4.76816 31.7475 4.89195 31.7406 5.01701C31.7325 5.20454 31.7601 5.3919 31.8217 5.56829C31.8834 5.74468 31.9779 5.90663 32.0998 6.04481C32.2087 6.16935 32.3383 6.27226 32.4824 6.34854C32.6551 6.44126 32.8453 6.49312 33.0394 6.5004ZM32.5631 5.59735C32.466 5.48679 32.4021 5.34903 32.3793 5.20107C32.3565 5.05311 32.3757 4.90143 32.4347 4.76473C32.4937 4.62804 32.5897 4.51233 32.7111 4.43186C32.8324 4.3514 32.9737 4.3097 33.1175 4.31193C33.2119 4.31483 33.3048 4.33745 33.3906 4.37844C33.4764 4.41943 33.5535 4.47797 33.6172 4.55057C33.7485 4.69739 33.8187 4.89238 33.8124 5.09295C33.8092 5.16016 33.7978 5.22668 33.7786 5.29091C33.7427 5.41799 33.6754 5.53301 33.5834 5.62447C33.4424 5.762 33.256 5.83774 33.0628 5.836C32.9682 5.83437 32.875 5.81231 32.789 5.77124C32.703 5.73016 32.6261 5.67096 32.5631 5.59735Z"
                  fill="white"
                />
                <path
                  d="M28.7863 18.9367L32.5681 6.06352C32.5766 6.01845 32.5942 5.97577 32.6196 5.93823C32.6451 5.9007 32.6778 5.86914 32.7157 5.84561C32.7535 5.82208 32.7957 5.8071 32.8395 5.80164C32.8833 5.79618 32.9277 5.80036 32.9698 5.81392C33.012 5.82747 33.0509 5.85009 33.0841 5.88031C33.1173 5.91054 33.1441 5.94769 33.1626 5.98938C33.1812 6.03106 33.1911 6.07633 33.1918 6.12227C33.1925 6.16821 33.184 6.21379 33.1667 6.25606L29.3875 19.1293C29.3771 19.1725 29.3584 19.213 29.3325 19.2485C29.3065 19.2839 29.274 19.3134 29.2367 19.3353C29.1995 19.3572 29.1584 19.371 29.1159 19.3757C29.0734 19.3805 29.0304 19.3762 28.9895 19.3632C28.9486 19.3501 28.9108 19.3285 28.8782 19.2996C28.8456 19.2708 28.819 19.2353 28.8 19.1954C28.7809 19.1555 28.7699 19.112 28.7676 19.0676C28.7652 19.0231 28.7716 18.9786 28.7863 18.9367Z"
                  fill="white"
                />
                <path
                  d="M33.6376 9.98796H33.6662C33.8308 9.98868 33.989 9.92139 34.1061 9.80084C34.1699 9.7393 34.2214 9.6652 34.2576 9.58281C34.2938 9.50043 34.3139 9.41139 34.3169 9.32084C34.3207 9.23041 34.3069 9.14012 34.2765 9.05533C34.2461 8.97055 34.1997 8.89299 34.1399 8.82728C34.0831 8.76005 34.0135 8.70581 33.9356 8.668C33.8576 8.6302 33.773 8.60964 33.687 8.60762C33.5141 8.60326 33.3465 8.67055 33.2212 8.79474C33.1368 8.87834 33.0741 8.98283 33.039 9.09847C33.0223 9.15676 33.0118 9.21678 33.0077 9.27745C33.0016 9.46022 33.0642 9.63826 33.1821 9.77372C33.2344 9.83524 33.2982 9.88506 33.3695 9.92016C33.453 9.96312 33.5445 9.98628 33.6376 9.98796ZM33.406 9.54864C33.3432 9.47636 33.3102 9.38126 33.3141 9.28386C33.3179 9.18645 33.3584 9.09455 33.4268 9.02796C33.4611 8.99329 33.5019 8.96647 33.5468 8.94919C33.5916 8.93191 33.6394 8.92457 33.687 8.92762C33.7327 8.92861 33.7777 8.93921 33.8192 8.95879C33.8608 8.97836 33.8982 9.0065 33.9291 9.04152C33.993 9.11359 34.027 9.20907 34.0236 9.30713C34.0201 9.40519 33.9796 9.49786 33.9109 9.56491C33.8766 9.59979 33.8358 9.62694 33.791 9.64467C33.7463 9.6624 33.6985 9.67033 33.6506 9.66796C33.6036 9.66682 33.5574 9.65508 33.5151 9.63355C33.4729 9.61201 33.4357 9.58119 33.406 9.54321V9.54864Z"
                  fill="white"
                />
                <path
                  d="M29.8377 21.9391L33.367 9.92555C33.4165 9.75199 33.5232 9.63538 33.6039 9.65436C33.6845 9.67335 33.708 9.84148 33.6559 10.0123L30.1292 22.0259C30.0771 22.1967 29.9704 22.3161 29.8924 22.2971C29.8143 22.2781 29.7882 22.11 29.8377 21.9391Z"
                  fill="white"
                />
                <path
                  d="M30.7617 8.37963H30.793C30.9567 8.37964 31.1138 8.3124 31.2302 8.19251C31.3585 8.06762 31.4344 7.895 31.4412 7.71218C31.448 7.52937 31.3853 7.35114 31.2667 7.21624C31.209 7.15025 31.1392 7.09702 31.0614 7.05975C30.9836 7.02248 30.8994 7.00192 30.8138 6.99929C30.7277 6.99677 30.642 7.01201 30.5616 7.04412C30.4812 7.07623 30.4077 7.12459 30.3453 7.18641C30.2609 7.27001 30.1983 7.3745 30.1631 7.49014C30.1483 7.54876 30.1387 7.60867 30.1345 7.66912C30.1305 7.75976 30.1438 7.85033 30.1738 7.93553C30.2037 8.02074 30.2496 8.09888 30.3089 8.16539C30.3615 8.22544 30.4241 8.27506 30.4937 8.31183C30.5772 8.35463 30.6687 8.37777 30.7617 8.37963ZM30.5327 7.94302C30.4693 7.87106 30.4358 7.77581 30.4397 7.67819C30.4437 7.58057 30.4846 7.48856 30.5535 7.42234C30.5855 7.38856 30.6236 7.36174 30.6656 7.34343C30.7076 7.32512 30.7527 7.31569 30.7982 7.31569C30.8437 7.31569 30.8888 7.32512 30.9308 7.34343C30.9728 7.36174 31.0109 7.38856 31.0428 7.42234C31.0756 7.45831 31.1009 7.50099 31.117 7.54768C31.1332 7.59436 31.1398 7.64403 31.1365 7.69353C31.1368 7.72588 31.1315 7.75803 31.1209 7.78844C31.1038 7.85027 31.0715 7.90636 31.0272 7.95115C30.9562 8.01881 30.8631 8.05568 30.767 8.05421C30.6779 8.05046 30.5938 8.01055 30.5327 7.94302Z"
                  fill="white"
                />
                <path
                  d="M27.7897 17.5268L30.5122 8.2549C30.5512 8.11931 30.6475 8.03253 30.7282 8.05965C30.8089 8.08676 30.8427 8.21422 30.8037 8.3471L28.0786 17.619C28.0396 17.7518 27.9433 17.8386 27.8626 17.8142C27.7819 17.7898 27.7507 17.6596 27.7897 17.5268Z"
                  fill="white"
                />
                <path
                  d="M36.6829 5.33708H36.7427C37.0812 5.33633 37.4061 5.1982 37.6485 4.95199C37.915 4.69367 38.0723 4.33573 38.0858 3.95674C38.0934 3.77004 38.0652 3.58362 38.0026 3.4085C37.94 3.23337 37.8444 3.07308 37.7214 2.93708C37.6034 2.79928 37.4597 2.68804 37.299 2.61016C37.1384 2.53229 36.9641 2.48942 36.787 2.4842C36.428 2.47642 36.0803 2.61566 35.8188 2.87199C35.645 3.04396 35.5168 3.25973 35.4466 3.49843C35.4117 3.61834 35.3908 3.74215 35.3841 3.86725C35.3683 4.24592 35.4975 4.61562 35.7433 4.89504C35.8526 5.01947 35.9832 5.12156 36.1285 5.19606C36.3014 5.28398 36.4905 5.33208 36.6829 5.33708ZM36.2066 4.43402C36.0746 4.28412 36.005 4.08593 36.0128 3.88271C36.0206 3.67948 36.1052 3.48772 36.2482 3.34928C36.3164 3.28117 36.397 3.22786 36.4851 3.19245C36.5733 3.15704 36.6673 3.14024 36.7617 3.14302C36.8562 3.14579 36.9492 3.16809 37.0353 3.20862C37.1214 3.24915 37.1989 3.3071 37.2633 3.37911C37.3924 3.52712 37.4615 3.72153 37.4559 3.92148C37.4527 3.98959 37.4414 4.05701 37.4221 4.12216C37.3861 4.24924 37.3188 4.36426 37.2269 4.45572C37.0863 4.59373 36.8994 4.66872 36.7063 4.66453C36.6122 4.66459 36.5191 4.64411 36.433 4.60441C36.3469 4.56471 36.2698 4.50667 36.2066 4.43402Z"
                  fill="white"
                />
                <path
                  d="M30.4648 24.4825L36.1622 5.07914C36.2429 4.80796 36.4433 4.61812 36.6099 4.67236C36.7765 4.7266 36.8442 4.99236 36.7609 5.27168L31.0635 24.6724C30.9828 24.9435 30.7824 25.1334 30.6158 25.0819C30.4492 25.0303 30.3816 24.7591 30.4648 24.4825Z"
                  fill="white"
                />
                <path
                  d="M27.4173 12.8923C26.2227 16.9601 25.0072 21.0767 24.9864 21.0767C24.7382 21.9161 24.2343 22.6483 23.551 23.1626C22.8677 23.6769 22.0421 23.9452 21.1994 23.9269C20.2187 23.9123 19.2648 23.591 18.4613 23.0049L19.8095 27.2164C20.8194 30.3296 22.5476 31.9079 25.028 31.9079C27.5084 31.9079 29.2367 30.3296 30.2491 27.2164L30.9987 24.8788L27.4173 12.8923ZM21.9958 10.6794L20.3561 5.06588C19.8095 3.3113 18.883 1.12012 16.7331 1.12012C14.5832 1.12012 13.2012 3.01842 13.2012 5.15537V5.94452C13.303 6.76967 13.4996 7.57908 13.7868 8.35537L17.5764 20.2876C18.0813 21.8659 19.2187 22.6116 20.3561 22.6116C22.8834 22.6116 23.724 19.8998 24.0598 17.7303C23.724 16.7676 22.9172 13.6191 21.9958 10.6794Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_435_88">
                  <rect width="38" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <div className="links_container">
          <div className="home"><NavLink className="home" to="/" activeClassName="active">Home</NavLink></div>
          <div className="about"> <NavLink className="about" to="/about" activeClassName="active">About</NavLink></div>
          <div className="services"> <NavLink className="services" to="/services" activeClassName="active">Services</NavLink></div>
        </div>


        <div className="sign">
          <p className="contact">Contact us</p>
          <button className="login" type="button">Login</button>
        </div>

        <div className='mobilenavbar'>
          {toggleMenu
            ? (
              <svg className="vector" width="1" height="1"
                viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
              >
              </svg>
            )
            : (
              <svg className="vector" width="30" height="30"
                viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                onClick={() => setToggleMenu(true)}
              >
                <path
                  d="M1.31507 2.97433H18.6849C19.3973 2.97433 20 2.40729 20 1.73716C20 1.06703 19.3973 0.5 18.6849 0.5H1.31507C0.602741 0.5 0 1.06703 0 1.73716C0 2.40729 0.547946 2.97433 1.31507 2.97433ZM18.6301 16.7893H1.31507C0.602741 16.7893 0 17.3564 0 18.0265C0 18.6966 0.602741 19.2636 1.31507 19.2636H18.6849C19.3973 19.2636 20 18.6966 20 18.0265C20 17.3564 19.3973 16.7893 18.6301 16.7893ZM18.6301 8.64466H1.31507C0.602741 8.64466 0 9.2117 0 9.88183C0 10.552 0.602741 11.119 1.31507 11.119H18.6849C19.3973 11.119 20 10.552 20 9.88183C20 9.2117 19.3973 8.64466 18.6301 8.64466Z"
                  fill="white"
                />
              </svg>
            )
          }
          {toggleMenu && (
            <div className="menu" >
              <div class="rectangle5" ref={rectangleRef}>
                <div >
                  <div className='navcontent'>
                    <div className='close' onClick={() => setToggleMenu(false)}></div>
                    <div className="links_container2">
                      <div className="home2">
                        <div className='rondelle'></div>
                        <div ><NavLink className='services' to="/" activeClassName="active">Home</NavLink></div>
                      </div>
                      <div className="about2">
                        <div className='rondelle'></div>
                        <div> <NavLink className='services' to="/about" activeClassName="active">About</NavLink></div>
                      </div>
                      <div className="services2">
                        <div className='rondelle'></div>
                        <div className='bottou'><NavLink className='services' to="/services" activeClassName="active">Services</NavLink></div>
                      </div>
                    </div>
                  </div>

                  <div className="sign2">
                    <button className="login" type="button">LOGIN</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
    </div>
  )
}

export default Navbar