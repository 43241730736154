import React from 'react'
import {Ellipse, Navbar, OurMission, Header, HomeSlider} from '../../components';
import { Footer } from '../../containers';
import './home.css';

const home = () => {
  return (
    <div className="Home" style={{display:"flex",flexDirection:"column"}}>
      <Navbar />
        <Header/>
      <Ellipse />
        <HomeSlider/>
      <OurMission />
      <Footer />
    </div>
  )
}

export default home
