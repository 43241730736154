import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, OurServices, AboutUs } from './pages';
import { Navbar } from './components';
import logo from './assets/logops.svg'


const App = () => {
  useEffect(() => {
    document.title = "WiseVision"; // Change "" to whatever you want the new title to be

    // Function to change the favicon
    const changeFavicon = (src) => {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = src;
    };

    // Call the function with the path to your favicon file
    changeFavicon(logo); // Change this to the actual path of your favicon
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/about" element={<AboutUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
